/* .appbar {
    display: flex;
    justify-content: space-between;
} */

.colorPrimary{
    color: brown
}
.colorDefault{
    color: brown
}
.colorSecondary{
    color: brown
}

button.selectorContratos {
    margin-left: 10px;
    background-color: #FFF;
}

button.selectorContratos:hover, .selectorContratos:focus {
    background-color: #731f1f;
    color: white;
    border-color: white;
}

